import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { Analytics } from "@vercel/analytics/react"
import React from "react";
registerCoreBlocks();
const App = () => {
  const routerquestion = useFieldAnswer("reason")
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        // @ts-ignore
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome-screen",
              attributes: {
                classnames: 'wel-scrn-ovrd',
                label: "Hey!",
                description: "Nice to meet you. Feel free to contact me.",
              }
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                layout: "split-right",
                label: "Let's start with your name"
              }
            },
            {
              name: "email",
              id: "email",
              attributes: {

                layout: "split-right",
                required: true,
                label: "And email?"
              }
            },
            {
              name: "multiple-choice",
              id: "reason",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: true,
                label: " What bring you here today?",
                choices: [
                  {
                    label: "Project collaboration",
                    value: "project"
                  },
                  {
                    label: "Job opportunity",
                    value: "job"
                  },
                  {
                    label: "Just saying hello",
                    value: "hello"
                  },
                  {
                    label: "Other",
                    value: "other"
                  }
                ]
              }
            },
            // @ts-ignore
            ...(routerquestion?.includes("project") ? [
              {
                name: "multiple-choice",
                id: "aors",
                attributes: {
                  required: true,
                  multiple: true,
                  verticalAlign: true,
                  label: " Which areas are you interested in?",
                  choices: [
                    {
                      label: "Backend/API Development",
                      value: "backend"
                    },
                    {
                      label: "System architecure/System design",
                      value: "architecture"
                    },
                    {
                      label: "Mobile/Web - Frontend Development",
                      value: "frontend"
                    },
                    {
                      label: "Other",
                      value: "other"
                    }
                  ]
                }
              }
            ] : []),
            // @ts-ignore
            ...(routerquestion?.includes("project") ? [
              {
                name: "multiple-choice",
                id: "timeline",
                attributes: {
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: " What's your timeline like for this project??",
                  choices: [
                    {
                      label: "ASAP",
                      value: "0"
                    },
                    {
                      label: "Within a week",
                      value: "7"
                    },
                    {
                      label: "Within a month",
                      value: "30"
                    },
                    {
                      label: "Just exploring for now",
                      value: "999"
                    }
                  ]
                }
              }
            ] : []),
            // @ts-ignore
            ...(routerquestion?.includes("project") ? [
              {
                id: "projectinfo",
                name: "long-text",
                attributes: {
                  label: "Tell me a bit about your project?",
                  required: true,
                  placeholder: "Write your answer here!"
                }
              },
            ] : []),
            // @ts-ignore
            ...(routerquestion?.includes("job") ? [
              {
                id: "companyname",
                name: "short-text",
                attributes: {
                  label: "Which company are you representing?",
                  required: true,
                  placeholder: "Write your answer here!"
                }
              },
            ] : []),
            // @ts-ignore
            ...(routerquestion?.includes("job") ? [
              {
                id: "positionid",
                name: "short-text",
                attributes: {
                  label: "Please describe the job role?",
                  required: false,
                  placeholder: "Write your answer here!"
                }
              }] : []),
            // @ts-ignore
            ...(routerquestion?.includes("job") ? [
              {
                name: "multiple-choice",
                id: "hiringspeed",
                attributes: {
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: " How urgent is this hiring need?",
                  choices: [
                    {
                      label: "ASAP",
                      value: "0"
                    },
                    {
                      label: "Within a month",
                      value: "30"
                    },
                    {
                      label: "Flexible",
                      value: "999-flexible"
                    },

                  ]
                }
              }] : []),
            {
              id: "extra-text",
              name: "long-text",
              attributes: {
                label: "What would you like to share or ask? (optional)?",
                required: false,
                placeholder: "Write your answer here!"
              }
            },
            {
              name: "statement",
              id: "g91imf1023",
              attributes: {
                label: "You are doing great so far!",
                buttonText: "Continue",
                quotationMarks: true
              }
            }
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false
          },
          messages: {
            'block.defaultThankYouScreen.label': "Thanks for reaching out.\n\n I will get back to you within 48 hours.",
          },
          theme: {
            font: "Inter",
            buttonsBgColor: "#696969",
            logo: {
              src: ""
            },
            questionsColor: "#FFF",
            answersColor: "#FFF",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#FFF",
            progressBarBgColor: "#ccc",
            backgroundColor: "#000"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          // @ts-ignore
          const DataObject = JSON.parse(JSON.stringify(data.answers));

          const formattedData = {};
          Object.entries(DataObject).forEach(([key, value]) => {
            if (!value.value) {
              formattedData[key] = "";
            } else {
              formattedData[key] = value.value.toString();
            }
          });
          fetch("https://submit-form.com/xuN1VR98", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            body: JSON.stringify(formattedData)
          })
            .then(function (response) {
              setIsSubmitting(false);
              completeForm();
            })
            .catch(function (error) {
              console.error(error);
            });
        }}
      />
      <Analytics />
    </div>
  );
};

export default App;
